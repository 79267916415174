import React from "react";
import { Route, Switch } from "react-router-dom";

import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";

import Home from "./components/Pages/Home/Home";
import Portfolio from "./components/Pages/Portfolio/Portfolio";
import Project from './components/Pages/Project/Project';
import AboutMe from './components/Pages/AboutMe/AboutMe';
import Contact from './components/Pages/Contact/Contact';
import NotFound from './components/Pages/NotFound/NotFound';

import ScrollToTop from './components/Utils/ScrollToTop';

import classes from "./App.module.css";

const app = () => {
  return (
    <div className={classes.App}>
      <Header />
      <ScrollToTop />
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/portfolio" exact component={Portfolio} />
        <Route path="/portfolio/rock-paper-scissors" exact component={() => <Project title="Rock, paper, scissors" videoLink="https://www.youtube.com/embed/tmwKggKqsLo" languages="Python, Javascript, Html, Scss" tech="Tensorflow, TensorflowJs" />} />
        <Route path="/portfolio/hair-segmentation" exact component={() => <Project title="Hair Segmentation" videoLink="https://www.youtube.com/embed/jg324mmtx-0" languages="Python, Dart" tech="Tensorflow, Flutter" />} />
        <Route path="/portfolio/mnist-classifier" exact component={() => <Project title="Mnist Classifier" videoLink="https://www.youtube.com/embed/RwP7NMv-j6E" languages="Python, Javascript, Html, Css" tech="Tensorflow, TensorflowJs" />} />
        <Route path="/about-me" exact component={AboutMe} />
        <Route path="/contact" exact component={Contact} />
        <Route path="*" exact component={NotFound} />
      </Switch>
      <Footer />
    </div>
  );
};

export default app;

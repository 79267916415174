import React from "react";
import { injectIntl } from 'react-intl';

import classes from './Project.module.css';

import ExternalLink from '../../UI/ExternalLink/ExternalLink';

const project = (props) => (
  <div className={classes.Project}>
    <h2>{props.title}</h2>
    <iframe className={classes.video} title={props.title} allowFullScreen src={props.videoLink}></iframe>
    <div className={classes.descriptionContainer}>
      <p>{props.intl.formatMessage({id: "home-skills-languages"})}: {props.languages}</p>
      <p>{props.intl.formatMessage({id: "home-skills-technologies"})}: {props.tech}</p>
    </div>
    <div className={classes.externalLinkContainer}>
      {(props.code) ? <ExternalLink link={props.code}>{props.intl.formatMessage({id: "button-code"})}</ExternalLink> : null}
      {
        (props.web) ? <ExternalLink link={props.web}>{props.intl.formatMessage({id: "button-web"})}</ExternalLink> : null
      }
    </div>
  </div>
);

export default injectIntl(project);

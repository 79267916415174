import React from "react";
import { injectIntl } from 'react-intl';

import NavigationItem from "./NavigationItem/NavigationItem";

import classes from "./NavigatiomItems.module.css";

const navigationItems = (props) => {
  const { intl } = props;
  const navClasses = [classes.NavigationItem];

  if (props.visible) {
    navClasses.push(classes.visible);
  }

  return (
    <div className={navClasses.join(' ')}>
      <ul>
        <NavigationItem clicked={props.clicked} exact link="/">
          {intl.formatMessage({id: "section-title-home"})}
        </NavigationItem>
        <NavigationItem clicked={props.clicked} link="/portfolio">{intl.formatMessage({id: "section-title-portfolio"})}</NavigationItem>
        <NavigationItem clicked={props.clicked} link="/about-me">{intl.formatMessage({id: "section-title-aboutMe"})}</NavigationItem>
        <NavigationItem clicked={props.clicked} link="/contact">{intl.formatMessage({id: "section-title-contact"})}</NavigationItem>
      </ul>
    </div>
  );
};

export default injectIntl(navigationItems);

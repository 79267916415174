import React from 'react';
import { injectIntl } from 'react-intl';

import classes from './Footer.module.css';

const footer = (props) => (
    <footer className={classes.Footer}>
        <p> {props.intl.formatMessage({id: "footer-copyright"}, {copyright: "\u00A9"})}</p>
    </footer>
);

export default injectIntl(footer);
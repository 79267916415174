import React, { useContext } from 'react';
import { Context } from '../../HOC/LanguageWrapper/LanguageWrapper';

import classes from './LanguageCmbBox.module.css';

const LanguageCmbBox = () => {
    const context = useContext(Context);
    return(
        <select className={classes.LanguageCmbBox} value={Context.locale} onChange={context.selectLanguage}>
            <option value="en-US">EN</option>
            <option value="es-HN">ES</option>
        </select>
    );
}

export default LanguageCmbBox;
import React from 'react';

import classes from './Portfolio.module.css';

import Portfolio from '../../Section/Portfolio/Portfolio';

const portfolio = () => (
    <div className={classes.Portfolio}>
        <Portfolio />
    </div>
);

export default portfolio;
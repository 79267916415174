import React, { Fragment, useEffect, useState } from "react";
import { injectIntl } from 'react-intl';

import Section from "../Section";
import Project from "../../Project/Project";
import withSection from "../../HOC/withSection";
import SearchButton from "../../UI/SearchButton/SearchButton";

import codingImage from "../../../assets/images/undraw_coding.svg";
import RockPaperScissorsImage from "../../../assets/images/Rock,Paper,Sicssors-Mockup.png";
import HairSegmentationImage from "../../../assets/images/Phone-HairColor-Mockup.png";
import MnistImage from "../../../assets/images/Mnist-Mockup.png";

import classes from "./Portfolio.module.css";

const Portfolio = (props) => {
  const { intl } = props;

  let isChanged = intl.formatMessage({id: "project-title-rockPaperScissors"});

  let allProjects =  [
      {
        type: "Web App",
        title: intl.formatMessage({id: "project-title-rockPaperScissors"}),
        image: RockPaperScissorsImage,
        alt: intl.formatMessage({id: "project-title-rockPaperScissors"}) + " " + intl.formatMessage({id: "image-alt"}),
        link: "/portfolio/rock-paper-scissors",
        onlyMobile: false,
        description:
          intl.formatMessage({id: "project-description-rockPaperScissors"}),
      },

      {
        type: "App",
        title: intl.formatMessage({id: "project-title-hairSegmentation"}),
        image: HairSegmentationImage,
        alt: intl.formatMessage({id: "project-title-hairSegmentation"}) + " " + intl.formatMessage({id: "image-alt"}),
        link: "/portfolio/hair-segmentation",
        onlyMobile: false,
        description:
        intl.formatMessage({id: "project-description-hairSegmentation"}),
      },

      {
        type: "Web App",
        title: intl.formatMessage({id: "project-title-mnistClassifier"}),
        image: MnistImage,
        alt: intl.formatMessage({id: "project-title-mnistClassifier"}) + " " + intl.formatMessage({id: "image-alt"}),
        link: "/portfolio/mnist-classifier",
        onlyMobile: false,
        description:
        intl.formatMessage({id: "project-description-mnistClassifier"}),
      },
    ];
    
  const [projectsState, setProjectsState] = useState([]);
  
  useEffect(() => {
    setProjectsState(allProjects.map((project) => project));
  }, [isChanged]);

  const searchFilterHandler = (btn) => {
    let projects = allProjects.map((project) => project);

    if (btn === "All") {
      setProjectsState(projects);
    } else {
      projects = projects.filter((project) => project.type === btn);

      setProjectsState(projects);
    }
  };

  // useEffect(() => {
  //   return () => {};
  // }, []);

  return (
    <Fragment>
      <Section
        title={intl.formatMessage({id: "section-title-projects"})}
        image={codingImage}
        alt={intl.formatMessage({id: "section-title-projects"}) + " " + intl.formatMessage({id: "image-alt"})}
        imageClass="ProjectsImage"
        invertedSection={true}
      >
        <div className={classes.searchButtons}>
          <p className={classes.searchParametersTitle}>{intl.formatMessage({id: "projects-subtitle-searchParameter"})}</p>
          <SearchButton onClick={searchFilterHandler.bind(this, "All")}>
            {intl.formatMessage({id: "projects-searchParameters-all"})}
          </SearchButton>
          <SearchButton onClick={searchFilterHandler.bind(this, "Web App")}>
          {intl.formatMessage({id: "projects-searchParameters-webApps"})}
          </SearchButton>
          <SearchButton onClick={searchFilterHandler.bind(this, "App")}>
          {intl.formatMessage({id: "projects-searchParameters-apps"})}
          </SearchButton>
        </div>
      </Section>
      <div className={classes.projects}>
        {
        (projectsState !== undefined) ? 
          projectsState.map((project) => (
            <Project
              key={project.title}
              title={project.title}
              image={project.image}
              alt={project.alt}
              link={project.link}
              onlyMobile={project.onlyMobile}
              description={project.description}
            ></Project>
          )) : null
        }
      </div>
    </Fragment>
  );
};

export default withSection(injectIntl(Portfolio));

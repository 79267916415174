import React from "react";
import ReactDOM from "react-dom";
import './normalize.css';
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import LanguageWrapper from './components/HOC/LanguageWrapper/LanguageWrapper';

import { BrowserRouter } from "react-router-dom";

const app = (
  <BrowserRouter>
    <React.StrictMode>
      <LanguageWrapper>
        <App />
      </LanguageWrapper>
    </React.StrictMode>
  </BrowserRouter>
);

ReactDOM.render(app, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React from "react";

import classes from "./ExternalLink.module.css";

const externalLink = (props) => {
  return (
    <a className={classes.ExternalLink} href={props.link}>
      {props.children}
    </a>
  );
};

export default externalLink;

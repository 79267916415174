import React from 'react';

import classes from './withSection.module.css';

const withSection = (WrappedComponent) => {
    return props => (
        <div className={classes.WithSection}>
            <WrappedComponent />
        </div>
    );
};

export default withSection;
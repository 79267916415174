import React from 'react';
import { injectIntl } from 'react-intl';

import skillsImage from '../../../assets/images/undraw_percentages_0rur.svg'; 
import Section from '../Section';
import withSection from '../../HOC/withSection';

import pythonLogo from '../../../assets/images/python-logo.svg';
import javascriptLogo from '../../../assets/images/javascript-logo.svg';
import dartLogo from '../../../assets/images/dart-logo.svg';
import tensorflowLogo from '../../../assets/images/tensorflow-logo.svg';
import reactLogo from '../../../assets/images/react-logo.svg';
import flutterLogo from '../../../assets/images/flutter-logo.svg';
import nodeLogo from '../../../assets/images/nodejs-logo.svg';
import flaskLogo from '../../../assets/images/flask-logo.svg';

import classes from './Skills.module.css';

const skills = (props) => {
    const { intl } = props;
    return(
        <Section title={intl.formatMessage({id: "section-title-skills"})} image={skillsImage} alt="Skills Image" imageClass="SkillsImage" invertedSection={true}>
            <div>
                <div className={classes.Skills} >
                    <p>{intl.formatMessage({id: "home-skills-languages"})}: </p>
                    <img className={classes.logo} src={pythonLogo} alt="Python Logo" />
                    <img className={classes.logo} src={javascriptLogo} alt="Javascript Logo" />
                    <img className={classes.logo} src={dartLogo} alt="Dart Logo" />
                </div>
                <div className={classes.Skills} >
                    <p>{intl.formatMessage({id: "home-skills-technologies"})}: </p>
                    <img className={classes.logo} src={tensorflowLogo} alt="Tensorflow Logo" />
                    <img className={classes.logo} src={reactLogo} alt="React Logo" />
                    <img className={classes.logo} src={flutterLogo} alt="Flutter Logo" />
                    <img className={classes.logo} src={nodeLogo} alt="Nodejs Logo" />
                    <img className={classes.logo} src={flaskLogo} alt="Flask Logo" />
                </div>
            </div>
        </Section>
    );
};

export default withSection(injectIntl(skills));
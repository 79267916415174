import React from "react";
import { injectIntl } from "react-intl";

import classes from "./NotFound.module.css";

import Section from "../../Section/Section";

import notFoundImage from "../../../assets/images/undraw_blank_canvas.svg";

const notFound = (props) => {
  const { intl } = props;

  return (
    <div className={classes.NotFound}>
      <Section
        title={intl.formatMessage({id: 'section-title-notFound'})}
        image={notFoundImage}
        alt={intl.formatMessage({id: "section-title-notFound"}) +  " " + intl.formatMessage({id: "image-alt"})}
        imageClass="AboutMeImage"
        invertedSection={true}
      >
        <p>{intl.formatMessage({id: "notFound-text"})}</p>
      </Section>
      <span></span>
    </div>
  );
};

export default injectIntl(notFound);

import React from "react";
import emaijs from 'emailjs-com';
import Swal from 'sweetalert2';
import { injectIntl } from 'react-intl';

import Section from "../../Section/Section";

import contactImage from "../../../assets/images/undraw_personal_text.svg";

import classes from "./Contact.module.css";

const Contact = (props) => {

  const { intl } = props;

  const sendEmail = (e) => {
    e.preventDefault();

    emaijs.sendForm('gmail', 'email_portfolio', e.target, 'user_e5ulMCQBwKROHqcCn4d38')
    .then((result) => {
      console.log(result)
      Swal.fire({
        title:"<p>" + intl.formatMessage({id: 'contact-form-messageSent'}) + "</p>",
        icon:'success',
        showConfirmButton: false,
        background: '#003143',
        timer: 1000
      })
    })
    .catch((error) => {
      console.log(error);
      Swal.fire({
        title:"<p>" + intl.formatMessage({id: 'contact-form-messageError'}) + "</p>",
        icon:'error',
        showConfirmButton: false,
        background: '#003143',
        timer: 1000
      });
    });

    e.target.reset();
  }

  return (
    <div className={classes.Contact}>
      <Section
        title={intl.formatMessage({id: 'section-title-contact'})}
        image={contactImage}
        alt= {intl.formatMessage({id: "section-title-contact"}) +  " " + intl.formatMessage({id: "image-alt"})}
        imageClass="AboutMeImage"
        invertedSection={true}
      >
        <form onSubmit={sendEmail}>
          <input type="text" name="name" placeholder={intl.formatMessage({id: "contact-form-name"})} />
          <input type="email" name="email" placeholder={intl.formatMessage({id: "contact-form-email"})} />
          <input type="text" name="subject" placeholder={intl.formatMessage({id: "contact-form-subject"})} />
          <textarea name="message" placeholder={intl.formatMessage({id: "contact-form-message"})} />
          <input type="submit" value={intl.formatMessage({id: "button-send"})} />
        </form>
      </Section>
      <span></span>
    </div>
  );
};

export default injectIntl(Contact);

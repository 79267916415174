import React from 'react';

import classes from './Main.module.css';

import githubLogo from '../../assets/images/github.svg';

const main = () => {
    return(
        <div id='Main' className={classes.Main}>
            <div className={classes.moon}>
                <span className={classes.crater1}></span>
                <span className={classes.crater2}></span>
                <span className={classes.crater3}></span>
            </div>
            <a className={classes.githubLogo} href="https://github.com/Carb2750"><img src={githubLogo} alt="Github Logo" /></a>
        </div>
    );
}

export default main;
import React from 'react';

import AboutMe from '../../Section/AboutMe/AboutMe'; 

import classes from './AboutMe.module.css';

const aboutMe = () => (
    <div className={classes.AboutMe}>
        <AboutMe />
    </div>
);

export default aboutMe;
import React, { Fragment } from "react";
import { injectIntl } from 'react-intl';

import Section from "../Section";
import withSection from "../../HOC/withSection";
import ExternalLink from '../../UI/ExternalLink/ExternalLink';

import aboutMeImage from "../../../assets/images/undraw_growth_curve.svg";
import resumePDF from '../../../assets/pdf/Resume.pdf';

import classes from "./AboutMe.module.css";

const aboutMe = (props) => {
  const { intl } = props;

  return(
    <Fragment>
      <Section
        title={intl.formatMessage({id: "section-title-aboutMe"})}
        image={aboutMeImage}
        alt={intl.formatMessage({id:"section-title-aboutMe"}) + " " + intl.formatMessage({id: "image-alt"})}
        imageClass="AboutMeImage"
        invertedSection={true}
      >
        <div className={classes.AboutMe}>
          <p className={classes.description}>
            {intl.formatMessage({id: "aboutMe-description-paragraph1"})}
          </p>
          <p className={classes.description}>
          {intl.formatMessage({id: "aboutMe-description-paragraph2"})}
          </p>
          <div className={classes.resumeContainer}>
            <ExternalLink link={resumePDF}>{intl.formatMessage({id: "button-resume"})}</ExternalLink>
          </div>
        </div>
      </Section>
      <span></span>
    </Fragment>
  );
};

export default withSection(injectIntl(aboutMe));

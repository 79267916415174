import React from 'react';

import classes from './Section.module.css';

const section = (props) => {

    const imageClasses = [classes.img];

    switch(props.imageClass) {
        case "ProjectsImage":
            imageClasses.push(classes.ProjectsImage);
            break;
        case "SkillsImage":
            imageClasses.push(classes.SkillsImage);
            break;
        case "AboutMeImage":
            imageClasses.push(classes.AboutMeImage);
            break;
        default:
            break;
    }

    const sectionClasses = [classes.Section];
    if(props.invertedSection) {
        sectionClasses.push(classes.invertedSection);
    }


    return(
        <div className={sectionClasses.join(' ')}>
            <div className={classes.general}>
                <h2 className={classes.title}>{props.title}</h2>
                <img className={imageClasses.join(' ')} src={props.image} alt={props.alt}/>
            </div>
            {props.children}
        </div>
    );
};

export default section;

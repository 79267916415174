import React from 'react';
import { injectIntl } from 'react-intl';

import Button from '../UI/Button/Button';

import classes from './Project.module.css';

const project = (props) => {
    const { intl } = props;
    const projectClasses = [classes.Project];

    if(props.onlyMobile) {
        projectClasses.push(classes.onlyMobile);
    }

    return (
        <div className={projectClasses.join(' ')} src={props.image} alt={props.alt} >
            <h3 className={classes.title}>{props.title}</h3>
            <img src={props.image} alt={props.alt} />
            <p>{props.description}</p>
            <Button link={props.link}>{intl.formatMessage({id: "button-seeMore"})}</Button>
        </div>
    );
}

export default injectIntl(project);
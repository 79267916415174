import React from 'react';
import { NavLink } from 'react-router-dom';

import classes from './Link.module.css';

const link = (props) => {
    return(
        <NavLink exact className={classes.Link} to={props.link}>{props.children}</NavLink>
    );
}

export default link;
import React from "react";

import Main from '../../Main/Main';
import Projects from '../../Section/Projects/Projects';
import Skills from '../../Section/Skills/Skills';

const home = () => {
  return (
    <div>
      <Main />
      <Projects />
      <Skills />
    </div>
  );
};

export default home;

import React from "react";
import { NavLink } from "react-router-dom";

import classes from './NavigationItem.module.css';

const navigationItem = (props) => (
  <li>
    <NavLink onClick={props.clicked} exact={props.exact} className={classes.link} activeClassName={classes.active} to={props.link}>
      {props.children}
    </NavLink>
  </li>
);

export default navigationItem;

import React from 'react';

import classes from './SearchButton.module.css';

const searchButton = props => {
    return(
        <div onClick={props.onClick} className={classes.SearchButton}>
            <p>{props.children}</p>
        </div>
    );
};

export default searchButton;
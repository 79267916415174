import React from "react";

import classes from "./HamburgerBtn.module.css";

const hamburgerBtn = (props) => {

  const hamburguerClasses = [classes.Hamburger];
  if(props.active) {
    hamburguerClasses.push(classes.active);
  }

  return (
    <div onClick={props.clicked} className={hamburguerClasses.join(' ')}>
      <span className={classes.line}></span>
      <span className={classes.line}></span>
      <span className={classes.line}></span>
    </div>
  );
};

export default hamburgerBtn;

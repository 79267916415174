import React, { Fragment } from 'react';
import { injectIntl } from 'react-intl';

import Section from '../Section';
import Project from '../../Project/Project';
import Link from '../../UI/Link/Link';
import withSection from '../../HOC/withSection';

import codingImage from '../../../assets/images/undraw_coding.svg'; 
import RockPaperScissorsImage from '../../../assets/images/Rock,Paper,Sicssors-Mockup.png';
import HairSegmentationImage from '../../../assets/images/Phone-HairColor-Mockup.png';

const projects = (props) => {
    const { intl } = props;
    return(
        <Fragment>
            <Section title={intl.formatMessage({id: "section-title-projects"})} image={codingImage} alt="projects image" imageClass="ProjectsImage" invertedSection={false}>
                <Project title={intl.formatMessage({id: "project-title-rockPaperScissors"})} image={RockPaperScissorsImage} alt="Rock, Paper, Scissors" link="/portfolio/rock-paper-scissors" onlyMobile={false} />
            </Section>
            <Project title={intl.formatMessage({id:"project-title-hairSegmentation"})} image={HairSegmentationImage} alt="Hair Segmentation" link="/portfolio/hair-segmentation" onlyMobile={true} />
            <Link link="/portfolio">{intl.formatMessage({id: "button-moreProjects"})}</Link>
        </Fragment>
    );
}

export default withSection(injectIntl(projects));
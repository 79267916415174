import React, { useState } from "react";
import { IntlProvider } from "react-intl";

import English from "../../../assets/languages/en-US.json";
import Spanish from "../../../assets/languages/es-HN.json";

export const Context = React.createContext();

const local = navigator.language;

let lang;
switch (local) {
  case "es-HN":
  case "es-AR":
  case "es-BO":
  case "es-CL":
  case "es-CO":
  case "es-CR":
  case "es-DO":
  case "es-EC":
  case "es-ES":
  case "es-GT":
  case "es-MX":
  case "es-NI":
  case "es-PA":
  case "es-PE":
  case "es-PR":
  case "es-PY":
  case "es-SV":
  case "es-US":
  case "es-UY":
  case "es-VE":
    lang = Spanish;
    break;
  default:
    lang = English;
}

const LanguageCmbBox = (props) => {
  const [locale, setLocale] = useState(local);
  const [messages, setMessages] = useState(lang);

  const selectLanguage = (e) => {
    const newLocale = e.target.value;
    setLocale(newLocale);

    switch (newLocale) {
      case "es-HN":
        setMessages(Spanish);
        break;
      default:
        setMessages(English);
    }
  };

  return (
    <Context.Provider value={{locale, selectLanguage}}>
      <IntlProvider messages={messages} locale={locale}>
        {props.children}
      </IntlProvider>
    </Context.Provider>
  );
};

export default LanguageCmbBox;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import HamburgerBtn from "./HamburgerBtn/HamburgerBtn";
import NavigationItems from "../Navigation/NavigationItems/NavigationItems";
import LanguageCmbBox from "./LanguageCmbBox/LanguageCmbBox";

import classes from "./Header.module.css";

import logo from "../../assets/images/Logo.svg";

const Header = (props) => {
  const [openedState, setOpened] = useState(false);

  const [scrollPosition, setSrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setSrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const onBurgerClicked = (e) => {
    const prevState = openedState;
    setOpened(!prevState);
  };

  const headerClasses = [classes.Header];
  if (scrollPosition > 50) {
    headerClasses.push(classes.navBarColor);
  }

  return (
    <div className={headerClasses.join(" ")}>
      <Link to={"/"}>
        <img className={classes.logo} src={logo} alt="Christian's Logo" />
      </Link>
      <LanguageCmbBox />
      <HamburgerBtn active={openedState} clicked={onBurgerClicked} />
      <NavigationItems clicked={onBurgerClicked} visible={openedState} />
    </div>
  );
};

export default Header;
